.barContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 4px;
  width: 95%;
}

.inactiveBar {
  display: flex;
  height: 4px;
  width: 100%;
  background-color: #e4e4e8;
}

.activeBar {
  display: flex;
  height: 4px;
  background-color: #6f6bf5;
  position: absolute;
}

.container {
  display: flex;
  height: 30px;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.textContainer {
  display: flex;
  width: 90%;
  height: 20px;
  justify-content: flex-start;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  text-align: left;
  color: #5c5c5c;
}
