.container {
  display: flex;
  flex-direction: row;
  border: 1px solid #8a8cb3;
  box-sizing: border-box;
  border-radius: 16px;
  height: 48px;
  width: 100%;
}

.input {
  border: none;
  box-sizing: border-box;
  border-radius: 16px;
  width: 100%;
  height: 46px;
  padding-left: 1rem;
  border-radius: 16px 0px 0px 16px;
}

.input:focus {
  outline-width: 0;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
