.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 3rem;
  height: 100vh;
  width: 50%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.rightContainer {
  overflow-y: auto;
  height: auto;
  width: 50%;
  padding: 5rem;
}

.sectionTitleText {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #5c5c5c;
}

.subSectionTitleText {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #5c5c5c;
}

.contentSectionText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #5c5c5c;
}

