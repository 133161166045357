.container {
  box-sizing: border-box;
  display: flex;
}

.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: 100%;
}

.main {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 1rem;
}

.placeholderData {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px;
}

.placeholderData img {
  width: 100%;
  margin-bottom: 20px;
}
