.containerSmall {
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.containerLarge {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  max-width: 100%;
}

.Card {
  margin: 5px 10px;
  color: #5c5c5c;
  background: #ffffff;
  border-radius: 10px;
}
