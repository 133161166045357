@media screen and (max-width: 1500px) {
  .contentContainer {
    margin-top: 10px;
    width: 93%;
    height: 200px;
    background: #f3f4f9;
    border-radius: 9px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 1500px) {
  .contentContainer {
    margin-top: 10px;
    width: 95%;
    height: 200px;
    background: #f3f4f9;
    border-radius: 9px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.headerTitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerTitleText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #171b2b;
}

.headerTitleEditText {
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5652e5;
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  height: 3.5rem;
  justify-content: space-between;
}
.accountDataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  height: 5rem;
}

.descripctionDataContainer {
  margin-left: 2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #a3a3a3;
  width: 20rem;
  height: auto;
}

.accountDataTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #5c5c5c;
  width: 5rem;
  height: auto;
}
