.container {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1500px) {
  .avatarContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 17rem;
    width: 100%;
    justify-content: center;
  }
}
@media only screen and (min-width: 1500px) {
  .avatarContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 17rem;
    width: 70%;
    justify-content: center;
  }
}

.titleAvatarContainer {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  margin-top: 3rem;
  align-items: start;
  justify-content: center;
  width: 60%;
  height: 100%;
}

.avatarText {
  color: #ffffff;
}

.content {
  display: flex;
  height: 80%;
  width: 100%;
  background-color: #ffffff;
}

.avatarBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  padding: 4px;
  background: #e9eaef;
}

.contentInnerContainer {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 100%;
}

.rightContentcontainer {
  display: flex;
  width: 40%;
  height: 80vh;
  padding-top: 1rem;
  justify-content: center;
}

.rightinnerContentcontainer {
  display: flex;
  overflow: auto;
  padding-top: 5%;
  flex-direction: column;
  width: 80%;
  height: 75vh;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
}

.contentContainer {
  width: 90%;
  height: 30%;
  padding-top: 1rem;
  margin-left: 2rem;
  border-radius: 8px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #414446;
}

.companyTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #414446;
}
