.container {
  box-sizing: border-box;
  display: flex;
  height: auto;
  flex-grow: 0;
  z-index: 10;
}

.containerCompanies {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 64px;
}

.containerMenu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  min-height: 100vh;
  height: auto;
  width: 240px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.menuTitleContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 60px;
  width: 100%;
}

.iconMenuContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  width: 3rem;
  cursor: pointer;
}

.menuTitle {
  color: var(--gray);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 15px;
  padding-top: 20px;
}

.divider {
  display: flex;
  width: 100%;
  height: 1px;
  background: var(--purple-inactive-light);
}

.logoContainer {
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
}

.barContainer {
  margin-top: 10px;
  display: flex;
  width: 40px;
  height: 40px;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border-color: var(--white);
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
}

.iconTabContainerSideMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.iconTabContainerSideMenuFit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.sectionMenuContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 10px;
}

.navLink {
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  margin-bottom: 23px;
  border-radius: 9px;
}


.navLink__inner {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
}

.navLink__inner .icon {
  margin-right: 20px;
  width: 20px;
}

.navLink__text a {
  text-decoration: none;
}
