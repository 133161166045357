@import url(/src/styles/index.css);

@font-face {
  font-family: Roobert;
  src: local(Roobert-Light), local('Roobert Light'),
    url(./fonts/Roobert/Roobert-Light.ttf) format('truetype');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: Roobert;
  src: local(Roobert-Regular), local('Roobert Regular'),
    local(Roobert),
    url(./fonts/Roobert/Roobert-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Roobert;
  src: local(Roobert-bold), local('Roobert bold'),
    url(./fonts/Roobert/Roobert-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

html * {
  font-family: Roobert, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
