.container {
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 30rem;
  background: #ffffff;
  border-radius: 8px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #5c5c5c;
}

.titleContainer {
  height: 120px;
}

.list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  height: 40px;
  padding-left: 10%;
}

.rowText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #5c5c5c;
}
