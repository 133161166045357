.container {
  display: flex;
  flex-direction: column;
  width: 25rem;
  padding: 30px;
  background: #ffffff;
  border-radius: 8px;
}

.title {
  margin-bottom: 37px;
}

.form {
  width: 100%;
}

.label {
  margin-bottom: 5px;
}
