.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  height: 14rem;
  width: 14rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 5;
}

.containerLeft {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
  width: 14rem;
  top: 4rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 5;
}

.dot {
  position: absolute;
  bottom: 49%;
  width: 0.4rem;
  height: 0.4rem;
  background-color: #5652e5;
  border-radius: 50%;
}

.line {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 5rem;
  width: 0.2rem;
  transform-origin: bottom;
  /* background-color: red; */
  z-index: 10;
}

.lineColor {
  height: 4rem;
  width: 0.2rem;
  background-color: #5652e5;
  z-index: 10;
}

.numberText {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #757575;
}

.numberSelectedText {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
}

.numberUnSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 1rem;
  color: #757575;
}

.numberSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #5652e5;
  color: white;
}

.number {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform-origin: bottom;
}

.number1 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(30deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number2 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(60deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number3 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(90deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number4 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(120deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number5 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(150deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number6 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(180deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number7 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(210deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number8 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(240deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number9 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(270deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number10 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(300deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number11 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(330deg);
  transform-origin: bottom;
  cursor: pointer;
}

.number12 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 5rem;
  transform: rotate(0deg);
  transform-origin: bottom;
  cursor: pointer;
}

.circle {
  display: flex;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  background: #dfdeff;
  justify-content: center;
}

.bottomContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 3rem;
  width: 100%;
  bottom: 0%;
}

.covertTimeSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: #5652e5;
  color: #ffffff;
}

.covertTime {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
}

.timeInputContainer {
}

.timeInputInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 12px;
  width: 100%;
  height: 48px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
}

.timeInputInnerContainerError {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 12px;
  width: 100%;
  height: 48px;
  border: 1px solid red;
  box-sizing: border-box;
  border-radius: 4px;
}

.hoursInput {
  width: 14px;
  height: 38px;
  border: none;
  outline: none;
}

.minutesInput {
  width: 14px;
  height: 38px;
  border: none;
  outline: none;
}

.covertTimeInput {
  width: 20px;
  height: 38px;
  border: none;
  outline: none;
}

.iconInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 82%;
}

.iconClockInputContainer {
  display: flex;
  padding-top: 2px;
}

.iconChevronInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
