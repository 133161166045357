.containerSmall {
  position: absolute;
  width: 502px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.containerNormal {
  position: absolute;
  width: 502px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.containerLarge {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}
