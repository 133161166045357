.container {
  border: 1px solid #8a8cb3;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  height: 48px;
  padding-left: 1rem;
  margin-top: 5px;
}

.container:focus {
  outline-width: 0;
}

label {
  color: #414446;
  font-size: 14px;
  display: inline;
  margin-top: 5px;
}
