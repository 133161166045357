.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightContainer {
  padding-top: 2%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 96vh;
  flex-direction: row;
  justify-content: center;
}

.title {
  margin-top: 3rem;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5c5c5c;
}

.subtitle {
  margin-top: -1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  color: #5c5c5c;
}

.formContainer {
  box-sizing: border-box;
  width: 100%;
}

.row button {
  width: 100% !important;
  height: 60px !important;
  font-size: 18px;
  margin-bottom: 20px;
}
.row button h6 {
  font-size: 18px;
}

@media screen and (max-width: 1500px) {
  .cardInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 420px;
  }
  .row {
    width: 100%;
    padding-bottom: 15px;
  }

  .header {
    padding-bottom: 10px;
  }

  .react-tel-input .form-control {
    width: 100% !important;
    height: 48px !important;
  }

  .rowCenter {
    text-align: center;
    padding-bottom: 30px;
    color: #5c5c5c;
    font-size: 12px;
  }

  .rowCenter span {
    color: #615dec;
    font-weight: 500;
    cursor: pointer;
    margin-left: 0px;
  }

  .rowCenter input[type='checkbox'] {
    border: 1px solid #615dec;
    margin: 10px !important;
  }
}

@media screen and (min-width: 1500px) {
  .cardInnerContainer {
    display: flex;
    padding-top: 60px;
    flex-direction: column;
    align-items: center;
    min-width: 420px;
  }
  .row {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header {
    padding-bottom: 30px;
  }

  .react-tel-input .form-control {
    width: 100% !important;
    height: 48px !important;
  }

  .rowCenter {
    text-align: center;
    padding-bottom: 30px;
    color: #5c5c5c;
    font-size: 12px;
  }

  .rowCenter span {
    color: #615dec;
    font-weight: 500;
    cursor: pointer;
    margin-left: 0px;
  }

  .rowCenter input[type='checkbox'] {
    border: 1px solid #615dec;
    margin: 10px !important;
  }
}
