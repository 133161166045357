.selectTimePicker input {
  border: 1px solid #8a8cb3;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  height: 48px;
  padding-left: 1rem;
  margin-top: 5px;
}

.selectTimePicker {
  width: 100%;
}
