.codeInputs {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 5%;
}

.codeInput {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 60px;
  width: 40px;
  border-radius: 5px;
  margin: 0 8px;
  border: 1px solid #8a8cb2;
  font-size: 38px;
  padding: 0;
}

.codeInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
