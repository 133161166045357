.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #fff;
  border: 2px solid #615dec;
  border-radius: 15px;
  color: #615dec;
  font-family: 'RoobertBold', sans-serif;
  font-size: 14px;
  height: 40px;
  outline: none;
  width: 100%;
}

@media only screen and (min-width: 1024px) and (max-width: 1500px) {
  .buttonLeftIcon {
    justify-content: space-between;
    padding-right: 3.5rem;
    padding-left: 1rem;
  }
}

@media screen and (min-width: 1500px) {
  .buttonLeftIcon {
    justify-content: space-between;
    padding-right: 6.5rem;
    padding-left: 1rem;
  }
}

.button .text .FontAwesomeIcon {
  margin-right: 20px;
  font-size: 20px;
  margin-bottom: -2px;
}

.text {
  font-family: 'RoobertBold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.button:hover {
  transition-duration: 0.1s;
  opacity: 0.8;
  cursor: pointer;
}

.button:active {
  background-color: #fff;
  box-shadow: 0 1px #666;
  transform: translateY(1px);
}
