.container {
  display: flex;
  min-height: 100vh;
  height: auto;
  width: 100vw;
  flex-direction: column;
}

.containerForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  width: 100%;
}

.header {
  display: flex;
  height: 10vh;
  width: 100%;

  padding-top: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: auto;
  margin-bottom: 4rem;
  min-height: 20rem;
  min-width: 500px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  background-color: white;
}

.formBack {
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-left: 2rem;
}

.formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.formInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  align-self: center;
}

.inputContainer {
  margin-top: 1rem;
  width: 100%;
}

.checkoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  height: 16rem;
  margin-left: 5rem;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
}

.checkoutTop {
  background-color: white;
  height: 2rem;
  width: 100%;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.checkoutBottom {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 7rem;
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.leftCheckout {
  background-color: white;
  height: 100%;
  width: 10%;
}
.rightCheckout {
  background-color: white;
  height: 100%;
  width: 10%;
}

.checkoutInnerContainer {
  display: flex;
  flex-direction: row;
  width: 30vw;
}

.checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  height: 10rem;
  margin-left: 5rem;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  background-color: white;
  z-index: 10;
}

.checkoutPrice {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  background-color: transparent;
  width: 100%;
  height: 7rem;
  border-radius: 15px;
  z-index: 10;
}

.price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 3.5rem;
}

.priceTitle {
  height: 2.5rem;
}

.priceTitleText {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff;
}

.priceNumberText {
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  color: #ffffff;
}

.priceMonthlyText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #e9eaef;
}

.stripe {
  display: flex;
  height: 20rem;
  width: 90%;
}

.priceToChargeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
}

.priceToChargeTitleText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #414446;
}

.totalContainer {
  display: flex;
  flex-direction: row;
}

.totalChargeTitle {
}

.totalChargeTitleText {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  color: #414446;
}

.moneyText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;

  color: #414446;
}

.devider {
  height: 1px;
  width: 80%;
  align-self: center;
  background-color: #5652e5;
}

.titleText {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #414446;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 90%;
  margin-top: 2rem;
}
