.container {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.title {
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
}
