.container {
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 30px;
  background: #ffffff;
  border-radius: 16px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* or 100% */
  text-align: center;

  color: #414446;
}

.titleContainer {
  text-align: center;
  padding-top: 15px;
}

.list {
  width: 100%;
}

.row {
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  justify-content: space-between;
}

.rowColumnSelector {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: right;
}
.rowColumnSelectorTitle {
  display: flex;
  width: 5rem;
  align-items: center;
  justify-content: left;
}

.rowColumn {
  display: flex;
  width: 25%;
  align-items: center;
}

.rowColumnToggleTitle {
  display: flex;
  width: 7rem;
  align-items: center;
}
.rowColumnToggle {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-evenly;
}

.rowColumnDays {
  display: flex;
  width: 25%;
  align-items: center;
}

.rowColumnDaysTitle {
  display: flex;
  width: 3.8rem;
  align-items: center;
}

.rowText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #5c5c5c;
}

.mb6 {
  width: 50%;
}

.rowTwo {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 30px;
  height: 60px;
  width: 100%;
}

.datePickerSelector {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 2px 6px 8px;
  width: 84px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d2d7de;
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.day {
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #5c5c5c;
}

.faCaretDown {
  position: absolute;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 30px;
}

.titleRow {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #5c5c5c;
}

.toggleText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #5c5c5c;
}
