.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 75px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #5c5c5c;
  margin-bottom: 15px;
  margin-top: 15px;
}

.cardInnerContainer .row span {
  color: #5c5c5c;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
}

.row {
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
}

.cardContainer {
  width: 33%;
}

@media (max-width: 1600px) {
  .cardContainer {
    width: 40%;
  }
}

@media (max-width: 1200px) {
  .cardContainer {
    width: 70%;
  }
}

@media (max-width: 740px) {
  .cardContainer {
    width: 90%;
  }
}

.ProgressBar {
  margin-top: 15px;
}

.ProgressBar .label {
  text-align: end;
  color: #414446;
  font-size: 12px;
  margin-bottom: 10px;
}
