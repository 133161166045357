.container {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  height: 4rem;
  width: 100%;
  border-bottom: #e9eaef;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  justify-content: space-between;
  margin-left: -2rem;
  padding-right: 2rem;
}

.title {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #5c5c5c;
}

.headerLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.25rem;
  width: 5rem;
  height: 3rem;
  background: #ffffff;
  border: 1px solid #e9eaef;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.titleText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #5c5c5c;
  margin-left: 4rem;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 56px;
  padding: 4px;
}

.avatarDefault {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 56px;
  padding: 4px;
  background: #e9eaef;
}

.avatarDefaultText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.titleTextAnalitics {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #5c5c5c;
  margin-left: 4rem;
}
