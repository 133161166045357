.button {
  background: linear-gradient(
    166.98deg,
    #7773fa -3.04%,
    #5652e5 90.61%
  );
  border: none;
  border-radius: 15px;
  color: #fff;
  height: 60px;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) and (max-width: 1500px) {
  .buttonRightIcon {
    justify-content: space-between;
    padding-left: 3.5rem;
  }
}

@media screen and (min-width: 1500px) {
  .buttonRightIcon {
    justify-content: space-between;
    padding-left: 4.5rem;
  }
}

.button:disabled {
  opacity: 0.5;
}

.button:hover {
  transition-duration: 0.1s;
}

.button:active {
  background-color: #fff;
  box-shadow: 0 1px #666;
  transform: translateY(1px);
}

.text {
  font-family: 'RoobertBold', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
