.container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.container > * {
  box-sizing: border-box;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
}

.contentContainer > * {
  box-sizing: border-box;
}

/* filters handlers */
.filtersHandlersContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0 30px;
  margin: 1rem 0;
}

.filtersHandlersContainer_dropDown .label {
  margin-bottom: 5px;
}

.filtersHandlersContainer_dropDown .input {
  flex: 1;
  min-width: 205px;
}

.filtersHandlersContainer_dropDownContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 250px;
}

.dropDownDateRange {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid var(--gray-light);
}

/* Chars */
.chartsContainer {
  display: grid;
  grid-auto-rows: 367px;
  width: 100%;
  padding: 35px;
  gap: 21px;
  background: linear-gradient(135deg, #bd50f8, #30cdfa);
}

/* Missing Data */
.missingData {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 29px;
  gap: 26px;
}

/* Cards */
.topCards {
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.topCards_card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--white);
  width: 100%;
  flex-shrink: 10;
}

.topCards_card:last-of-type {
  flex-shrink: 9;
}

.card_data {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-top: 5px;
}

.data_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Carousels general */
.carousel {
  flex: 1;
  margin: 0 5px;
}

/* Campaign summary */
.campaignsSummaryCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid var(--purple-inactive);
}

.campaignsSummaryCard_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_campaignKind {
  display: flex;
  align-items: center;
}

.campaignKind_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  margin-right: 6px;
  background-color: var(--purple-inactive-light);
  border-radius: 2px;
}

.campaignsSummaryCard_data table {
  width: 100%;
  border-collapse: collapse;
}

.campaignsSummaryCard_data table td:first-of-type {
  width: 40%;
}

.campaignsSummaryCard_data table td {
  padding-bottom: 8px;
}

/* general */
.table {
  overflow-x: auto;
  padding: 20px;
  height: 100%;
}

.table table {
  color: var(--gray);
  border-collapse: collapse;
  width: 100%;
}

.table th {
  font-size: 12px;
}

.table td {
  height: 37px;
  border-bottom: 1px solid var(--gray-light);
  font-size: 14px;
  text-align: center;
}

.table tr:last-of-type td {
  border-bottom: unset;
}

.table th:first-of-type,
.table td:first-of-type {
  text-align: left;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  /* filters handlers */
  .filtersHandlersContainer {
    flex-direction: row;
  }

  .filtersHandlersContainer_dropDown .input {
    max-width: 250px;
  }

  /* Chars */
  .chartsContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 117px;
  }
  .topCards {
    flex-direction: row;
  }
}
