.container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  flex: 1;
}

.cardInnerContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 75px;
  width: 502px;
  height: min-content;
  background-color: var(--white);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #5c5c5c;
}

.row {
  width: 100%;
  padding-bottom: 15px;
}

.row h3 {
  margin-block-end: 5px;
}
.row button {
  width: 100% !important;
  height: 48px !important;
}

label {
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 15px;
  color: #5c5c5c;
  font-weight: 500;
  margin: 0;
  width: 100%;
}

.ProgressBar {
  margin-top: 15px;
}

.ProgressBar .label {
  text-align: end;
  color: #414446;
  font-size: 12px;
  margin-bottom: 10px;
}
