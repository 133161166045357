html,
:root {
  /* Primary brand colors */
  --bright-purple: #9442ff;
  --dynamic-blue: #00b6ff;
  --purple-inactive: #8a8cb3;
  --purple-inactive-light: #eceafe;
  --gray: #5c5c5c;
  --gray-light: #d2d7de;

  /* Grayscale */
  --white: #fff;
  --gray: #5c5c5c;
  --gray-light: #d2d7de;
  --gray-lightest: #f3f4f9;

  /* Secondary: orange */
  --orange-a: #f09433;
  --orange-b: #dc2743;

  /* Secondary: pink */
  --pink-a: #cc2366;
  --pink-b: #bc1888;

  /* Secondary: purples */
  --purple-a: #8002ef;
  --purple-b: #7229e5;
  --purple-c: #6405fa;
  --purple-d: #5e48ff;
  --purple-e: #630ead;
  --purple-f: #4e0191;
  --purple-g: #3f039c;

  /* Secondary: blues */
  --blue-a: #381cff;
  --blue-b: #0422fa;
  --blue-c: #2116e8;
  --blue-d: #03159c;
  --blue-e: #00b6ff;
  --blue-f: #39e3ff;
  --blue-g: #0073a1;

  /* Secondary: greens  */
  --green-a: #04ef8a;
  --green-b: #0dbc71;
  --green-c: #029154;
  --green-d: #0dfae4;
  --green-e: #34e8d7;
  --green-f: #089c8f;

  /* Status */
  --red: #f24242;
  --red-light: #fef7f7;
}
