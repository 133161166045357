.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardInnerContainer {
  display: flex;
  padding-top: 45px;
  flex-direction: column;
  align-items: center;
}

.cardInnerContainer button h6 {
  font-size: 18px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5c5c5c;
  padding-bottom: 10px;
}

.subTitle {
  font-size: 14px;
  color: #414446;
  padding-bottom: 20px;
  text-align: center;
}

.footer {
  padding-top: 30px;
  text-align: center;
  font-size: 14px;
  color: #414446;
  padding-bottom: 30px;
  border-top: solid 1px #c4c9d1;
}
