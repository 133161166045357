.container {
  display: flex;
  flex-direction: row;
}

.avatarContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20rem;
  justify-content: center;
}

.titleAvatarContainer {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  align-items: start;
  justify-content: center;
  width: 60%;
  height: 100%;
}

.avatarText {
  color: #ffffff;
}

.content {
  display: flex;
  height: 80%;
  width: 100%;
  background-color: #ffffff;
}

.avatarBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  padding: 4px;
  background: #e9eaef;
}

.contentInnerContainer {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 70%;
}

.rightContentcontainer {
  display: flex;
  width: 40%;
  height: 80vh;
  padding-top: 1rem;
  justify-content: center;
}

.rightinnerContentcontainer {
  display: flex;
  overflow: auto;
  padding-top: 5%;
  flex-direction: column;
  width: 80%;
  height: 75vh;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
}

.contentContainer {
  width: 90%;
  height: 30%;
  padding-top: 1rem;
  margin-left: 2rem;
  border-radius: 8px;
}

.feature {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 3rem;
}

.featureTitleText {
  font-style: normal;
  margin-left: 1rem;
  font-weight: 400;
  font-size: 14px;
  color: #5c5c5c;
}

.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #5c5c5c;
}

.linkText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* identical to box height */
  text-decoration-line: underline;
  color: #5c5c5c;
  cursor: pointer;
}

.devider {
  height: 1px;
  width: 95%;
  align-self: center;
  background-color: #e9eaef;
}

.tradeMark {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #5c5c5c;
}
