.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: transparent;
  border: 2px solid #615dec;
  border-radius: 15px;
  color: #615dec;
  font-family: 'RoobertBold', sans-serif;
  font-size: 14px;
  height: 40px;
  outline: none;
  width: 100%;
}

.text {
  font-family: 'RoobertBold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.button:hover {
  transition-duration: 0.1s;
  opacity: 0.8;
}

.button:active {
  box-shadow: 0 1px #666;
  transform: translateY(1px);
}

svg {
  margin-left: auto;
}
