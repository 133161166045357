.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 502px;
  height: min-content;
  background-color: var(--white);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 50px 60px;
}

.loginButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin-top: 5px;
}

.title {
  margin-bottom: 30px;
}

.headerContainer {
  display: flex;
  width: 100%;
  top: 10px;
  height: 50px;
  flex-direction: row;
  position: absolute;
}


.registrationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 2%;
  align-items: center;
}

.registrationText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  margin-right: 20px;
}

.buttonRegistrationContainer {
  display: flex;
  top: 10px;
  width: 150px;
  right: 10px;
}

.forgotPasswordBtn {
  margin-top: 15px;
}
